import React, {useState, useEffect} from 'react';
import './App.css';
import backendUrl from './backendUrl.js';

function LoggedIn({token}) {

    const [activeToken, setActiveToken] = useState(null);
    const [user, setUser] = useState(null);

    function refreshToken(){
        fetch(backendUrl + "/token/refresh/",
              {
                  "method": "POST",
                  "headers": {
                      "Content-Type": "application/json"
                  },
                  "body": JSON.stringify({
                      "refresh": token.refresh,
                  })
              })
            .then(response => response.json())
            .then(data => {
                setActiveToken(data);
            });
    }

    function getUser(){
        setUser({
            "name": "John Doe",
            "organization": "Test Organization",
            "agents": [
                {
                    "name": "Test Agent",
                },
                {
                    "name": "Test Agent 2",
                }
            ]
        })
    }


    useEffect(() => {
        setActiveToken(token);
        //updateToken();
    }, []);



    return (
        <div className="App">
            <header className="App-header">
                <img className="App-logo" alt="logo" />
                <p>
                    {JSON.stringify(activeToken)}
                </p>
                <p>
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

export default LoggedIn;
