function LoginError({error}) {
    if (!error) {
        return (<div></div>);
    }
    return (
        <div className="alert alert-danger" role="alert">
            {error}
        </div>
    )
}

export default LoginError;
