import logo from './logo.svg';

import React, {useState, useEffect} from 'react';

import LoggedIn from './LoggedIn.js';
import LoginError from './LoginError.js';
import backendUrl from './backendUrl.js';
import './App.css';

function App() {

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);

    function getToken(){
    }

    function login(event) {
        event.preventDefault();
        fetch(backendUrl + "/token/",
              {
                  "method": "POST",
                  "headers": {
                      "Content-Type": "application/json"
                  },
                  "body": JSON.stringify({
                      "username": username,
                      "password": password,
                  })
              })
            .then(response => response.json())
            .then(data => {
                if(data.detail) {
                    setError(data.detail);
                    return;
                }
                setToken(data);
            })
            .catch(err => {
                setError(err);
            });
    }

    if(!token) {
        return (
            <div className="App">
                    <p>
                        Login
                    </p>
                <form onSubmit={login}>
                <LoginError error={error} />

                    <p>
                        <input type="text" placeholder="username" onChange={(e) => setUsername(e.target.value)} />
                    </p>
                    <p>
                        <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
                    </p>
                    <button type="submit">Login</button>
                </form>
            </div>
        )
    } else {
        return (
            <LoggedIn token={token}/>
        );
    }
}

export default App;
